.popup-container {

  position: absolute;
  top: 0;
  right: -1px;
  bottom: 0;
  left: 50px;
  z-index: 150;

  overflow: hidden;

  .close {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid orangered;
    position: absolute;
    right: 25px;
    top: 25px;
    border-radius: 5px;
    z-index: 200;
    color: orangered;
  }

  .movable-container {
    height: 100%;
    overflow: hidden;
    transition: .3s ease-out;
  }

  .hidden {
    transform: translateY(calc(100% + 50px));
  }

  .active {

  }
}