@import "index";

main{

  display: flex;
  height: 100%;

  .left-bar {
    width: 65px;
    flex: none;
    background: #818181;
    display: flex;
    flex-direction: column;

    .logo {
      text-align: center;
      padding: 50px 0;
      flex: none;

      img {
        width: 50px;
        margin: auto;
      }
    }

    .name-bar {
      text-align: center;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 25px 0;
      height: calc(100% - 200px);

      img {
        height: 80%;
        max-height: 500px;
      }
    }
  }

  .image-bar {
    max-width: 300px;
    min-width: 100px;
    width: 25vw;
    background: #333;
    background-size: cover;
    background-position: 0 70%;
    z-index: 17;
    position: relative;

    @media (max-width: 1600px) or (max-height: 700px) {
      max-width: 200px;
    }

    @media (max-width: 1024px) {
      display: none;
    }
  }

  .content-area {
    display: flex;
    flex: 1;
    background: #787878;
    position: relative;

    .content {
      flex: 1;
      display: flex;
      flex-direction: column;

      .content-body {
        font-size: 14px;
        line-height: 20px;
        color: #fff;
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        position: relative;

        @media (max-width: 1024px) {
          padding: 0 25px 0 0;
        }

        .header {
          display: flex;
          margin: 50px 0 25px;
          height: 50px;
          width: 80%;

          @media (max-width: 992px) {
            width: 100%;
          }

          .heading {
            flex: 1;
            padding: 0 25px;
            height: 100%;
            display: flex;
            align-items: center;
            background: #737373;

            h1 {
              text-transform: uppercase;
              font-family: "Archivo Black", sans-serif;
              font-weight: 900;
              font-style: normal;
              font-size: 1.5vw;
              text-wrap: nowrap;
              color: #b3b3b3;
              padding: 0 25px;

              @media (max-width: 768px) {
                display: none;
              }
            }
          }

          .controls {
            flex: none;

            .btn {
              height: 100%;
              background: orangered;
              display: flex;
              align-items: center;
              justify-content: center;
              text-transform: uppercase;
              font-weight: 500;
              color: #fff;
              border-radius: 5px;
              padding: 0 25px;
              cursor: pointer;
            }
          }
        }

        .text-wrapper {
          padding: 0 25vw 0 50px;
          width: 100%;
          position: relative;
          z-index: 15;
          border-radius: 0 7px 0 0 ;
          overflow: hidden;
          font-weight: 200;
          flex: 1;

          @media (max-width: 1600px) {
            padding: 0 20vw 0 50px;
          }

          @media (max-width: 1367px) {
            padding: 0 15vw 0 50px;
          }

          @media (max-width: 992px) {
            padding: 0 10vw 0 50px;
          }

          @media (max-width: 768px) {
            padding: 0 0 0 50px;
          }

          @media (max-height: 700px) {
            margin: 15px 0 0;
          }

          .text-scroll {
            height: 100%;
            width: 100%;
            overflow: auto;
            padding-right: 5px;
            display: flex;

            .text {
              width: 100%;
              text-align: justify;

              h1 {
                margin-bottom: 25px;
                color: #a5a5a5;
                font-size: 25px!important;
                line-height: 32px;
                text-align: left;
              }
            }
          }
        }

        footer {
          flex: none;
          z-index: 10;
          position: relative;
          padding: 25px 25vw 0 0;

          @media (max-width: 1600px) {
            padding: 25px 20vw 0 0;
          }

          @media (max-width: 1367px) {
            padding: 25px 15vw 0 0;
          }

          @media (max-width: 992px) {
            padding: 25px 10vw 0 0;
          }

          @media (max-width: 768px) {
            padding: 25px 0 0 0;
          }

          .cookie-info {
            padding: 25px 0 25px 50px;
            font-size: 12px;
            line-height: 14px;
            color: #ccc;


            a {
              color: inherit;
              transition: .3s color;

              &:hover {
                color: $hover-color;
              }
            }


          }
        }

        .footer {
          height: 40px;
          background: #222;
          border-radius: 0 15px 0 0;
          padding: 0 50px 0 50px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          a {
            color: #777;
            text-decoration: none;
            font-size: 14px;
          }

          @media (max-width: 1600px) {
            padding: 0 25px 0 50px;
          }

          .socials {
            display: flex;
            margin: 0 -5px;
            align-items: center;

            .instagram {
              margin-right: 25px!important;
            }

            .btn {
              width: 25px;
              height: 25px;
              margin: 0 5px;
            }

            .contact {
              width: 25px;
              height: 25px;
              opacity: .5;
              margin-top: 5px;
            }

            img {
              width: 100%;
            }
          }
        }
      }
    }

    .right-bar {
      flex: none;
      width: 150px;
      background: #222;

      @media (max-width: 768px) {
        display: none;
      }

    }

    .absolute-position-picture {
      position: absolute;
      bottom: -15px;
      right: 50px;
      z-index: 100;

      @media (max-width: 1367px) {
        transform: translateX(calc(50% - 100px))
      }

      @media (max-width: 992px) {
        transform: translateX(calc(60% - 100px))
      }

      img {
        height: 90vh;
        max-height: 1000px;


        @media (max-width: 768px) {
          display: none;
        }
      }
    }
  }
}