@import "../../assets/varibles.scss";

.request-form-component {



  .thank-container {
    display: flex;
    width: 100%;
    height: 100%;


    .thank-block {

      margin: auto;
      text-align: center;
      text-transform: uppercase;
      max-width: 400px;


      .icon {
        i {
          font-size: 150px;
          color: $hover-color;
        }
        margin-bottom: 25px;
      }

      .text {
        font-size: 25px;
        line-height: 35px;
        text-align: center;
      }
    }
  }

  @media (max-width: 768px) {
    width: 100%;
  }

  h2 {
    color: #8a8a8a;
    text-transform: uppercase;
  }

  h3 {
    margin: 10px 0 0;
    text-transform: uppercase;
    color: #8a8a8a;
    font-weight: 400;
  }

  .section {
    padding: 25px 0;
    border-bottom: 1px solid #a5a5a5;

    h4 {
      color: #656565;
      margin: 5px 0 10px;
    }

    .field-group, .select-group {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -5px;
    }

    .specify .input-group {
      padding: 15px;
      background: $hover-color;
      border-radius: 5px;

      .label {
        color: #fff;
      }

    }

    .field, .select-group .item {
      padding: 0 5px;
      margin: 5px 0;

      &__max-col-4 {
        width: 25%;
      }

      &__max-col-2 {
        width: 50%;
      }

      &__max-col-1 {
        width: 100%;
      }

      @media (max-width: 1200px) {

        &__max-col-4, &__min-col-2 {
          width: 50%;
        }

      }

      @media (max-width: 600px) {
        width: 100%;

        &__min-col-1 {
          width: 100%;
        }

        &__min-col-2 {
          width: 50%;
        }
      }
    }
  }

  .field-group-section {
    border: 0;
  }
}