.radio-button-group-component {
  .select-group {

    .item {
      height: 40px;

      .inner {
        border-radius: 4px;
        //background: #bababa;
        display: flex;
        min-height: 40px;
        align-items: center;
        transition: .3s;
        cursor: pointer;
        overflow: hidden;

        &:hover {
          //background: #979797;
        }

        .radio-icon {
          display: flex;
          width: 40px;
          flex: none;

          .circle-outer {
            outline: 2px solid #888;
          }

          .circle-outer, .circle-inner {
            display: flex;
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #fff;
            margin: auto;

          }

          .circle-inner {
            width: 10px;
            height: 10px;
            background: #fff;

          }
        }

        .selection-label {
          flex: 1;
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
          color: #5a5a5a;
          overflow: hidden;
          line-height: 16px;
        }
      }
    }

    .active {
      .circle-outer {
        outline: 2px solid #449fdb!important;
      }

      .circle-inner {
        background: #449fdb!important;
      }
    }
  }
}