@import "../../assets/varibles.scss";

.selector-active {

  .input-group {
    .input-block {
      border-radius: 4px 4px 0 0;
    }
  }

  @media (max-width: 768px) {

    position: absolute!important;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    z-index: 25!important;

    .dropdown {
      flex: 1;
      overflow-y: auto;
      height: 100vh;

      .list {
        height: auto!important;
        overflow: auto;
      }
    }
  }
}


.selector-component {
  position: relative;

  .input-faked {
    height: 40px;
    display: flex;
    align-items: center;
    cursor: pointer;

    .value {
      padding: 0 15px;
      flex: 1;
    }

    .btn-icon {
      flex: none;
      transition: .2s;
      width: 40px;
      height: 40px;
      flex: none;
      display: flex;

      i {
        display: block;
        margin: auto;
        transform: rotate(90deg);
      }
    }
  }

  .error {
    color: red;
    font-weight: 700;
  }

  .dropdown {
    background: #fff;
    position: absolute;
    width: 100%;
    z-index: 500;
    border-radius: 0 0 4px 4px;

    .list {
      height: 300px;
      overflow-y: auto;

      li {
        padding: 5px 15px;
        font-size: 12px;
        font-weight: 500;
        list-style: none;
        cursor: pointer;
        transition: .3s;
      }
    }
  }
}