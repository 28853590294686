@import "../../assets/varibles";

.faq-component {


  .faq-group {
    border-radius: 0 0 4px 4px!important;
    overflow: hidden;
    margin-bottom: 2px;

    .question {
      background: rgba(90, 90, 90, 0.1);
      display: flex;
      transition: .5s;
      cursor: pointer;
      font-size: 12px;
      align-items: center;
      padding: 10px 0;
      line-height: 14px;

      &:hover {
        background: rgba(90, 90, 90, 0.3);
      }

      .num {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 50px;
        flex: none;
        font-weight: 600;
      }

      .text {
        flex: 1;
        font-weight: 600;
        text-align: left!important;
      }

      .icon {
        width: 50px;
        flex: none;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: .3s;
      }
    }

    .answer {
      font-size: 14px;
      line-height: 18px;
      color: #222;
      font-weight: 500;
      max-height: 0;
      overflow: hidden!important;
      transition: max-height 0.2s;

      .text {
        width: 100%!important;
        background: #ececec;
        padding: 25px;
      }

      ul, ol {
        margin-left: 20px;
      }
    }


  }

  .active {

    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);

    .question {
      background: $hover-color;
      border-radius: 4px 4px 0 0;
      color: #fff;

      &:hover {
        background: $hover-color;
      }
    }


    .icon {
      transform: rotate(90deg);
    }

    .answer {
      max-height: calc(100vh * 10);
      transition: max-height 0.8s;
    }
  }
}