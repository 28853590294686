.error-table {

  span {
    display: inline-block;
    padding: 2px 5px;
    background: orangered;
    border-radius: 2px;
    font-size: 9px;
    text-transform: uppercase;
    color: #fff;
    font-weight: 600;
  }
}

.input-group {

  .label-group {
    display: flex;
    justify-content: space-between;



    .label {
      font-size: 12px;
      text-transform: uppercase;
      color: #676767;
      margin-bottom: 5px;
      font-weight: 600;
    }
  }

  .input-block {
    .PhoneInput {
      .PhoneInputCountry {
        .PhoneInputCountryIcon {
          margin: 0 15px;
          box-shadow: none;
        }
      }
    }
  }


  .input-block, .textarea-block {
    width: 100%;
    background: #fff;
    overflow: hidden;
    border-radius: 4px;

    input, textarea {
      width: 100%;
      background: transparent;
      padding: 0 15px;
      border: 0;

      &:focus {
        outline: none;
      }
    }

    input {
      height: 40px;
    }

    textarea {

    }
  }

  .textarea-block {
    padding: 5px;
  }

  .counter {
    text-align: right;
    font-weight: 400;
    font-size: 12px;
    margin: 5px 0;
  }
}