@import "../../assets/varibles.scss";

:root {
  --header-size: 65px;
}

@media (max-width: 1400px) {
  :root {
    --header-size: 65px;
  }
}

.locale-selector-component {

  .locale-container {
    width: var(header-size)!important;
    text-align: center;

    .current-locale-btn {
      background: #8f8f8f !important;
      text-transform: uppercase;
      font-weight: 700;
      color: #5a5a5a!important;
    }

    .locale-btn {
      display: flex;
      width: var(--header-size);
      height: var(--header-size);
      align-items: center;
      justify-content: center;
      color: #fff;
      top: 0;
      cursor: pointer;
    }

    .dropdown {
      overflow: hidden;
      position: absolute;
      z-index: 20;
      background: rgba(51, 51, 51, 0.35);
      height: 100%!important;
      backdrop-filter: blur(5px);

      .locale-btn {
        flex: none;
        width: var(header-size);
        height: var(header-size);

        font-weight: 700;
        font-size: 12px!important;
        color: #ff7441;
      }

      .current {
        color: #8e8e8e;
        font-weight: 700;
      }
    }

  }

  .active {
    i {
      transform: rotate(90deg);
    }
  }
}