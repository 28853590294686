@import "../../assets/varibles.scss";

.button-loading-component {

  display: flex;
  justify-content: center;

  .send-button {

    -webkit-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);
    -moz-box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);
    box-shadow: 0px 5px 5px 0px rgba(0,0,0,0.1);
    width: 200px;
    height: 40px;
    border-radius: 7px;
    background: #101115;
    overflow: hidden;

    button {
      border: 0;
      background: $hover-color;

      font-weight: 600;
      text-transform: uppercase;
      font-size: 12px;
      color: $main-accent-text;
      cursor: pointer;
      transition: .3s;
      height: 100%;
      width: 100%;

      &:hover {
        filter: brightness(1.1);
      }
    }
  }
}

.loader {
  width: 100%;
  font-weight: 400;
  font-size: 12px;
  background:linear-gradient(90deg,$main-accent-color 50%,#0000 0) right/200% 100%;
  animation: l21 2s infinite linear;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: uppercase;

}
.loader::before {
  content :"Sending...";
  color: #fff;
  background: inherit;

  -webkit-background-clip:text;
  background-clip:text;
}

@keyframes l21{
  100%{background-position: left}
}

.locale-ru {
  .loader::before {
    content: "Отправляю...";
  }
}