.mobile-view {
  .sliding-container-component {
    width: calc(100% - 50px);

    .btn-close {
      right: 25px;
      top: 25px;
    }

    .sliding-panel {

      .slider-body {
        width: 100%;
        padding: 25px 25px 100px;
      }
    }
  }
}

.sliding-container-component {
  position: absolute;
  width: calc(100% - 150px);
  z-index: 16;
  overflow: hidden;

  @media (max-width: 768px) {
    width: 100%;
  }

  .btn-close {
    display: flex;
    position: absolute;
    right: 50px;
    top: 58px;
    border: 2px solid orangered;
    width: 30px;
    height: 30px;
    font-size: 16px;
    color: orangered;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    cursor: pointer;
    z-index: 20;
  }

  .sliding-panel {
    width: 100%;
    height: 100vh;
    background: #c1c1c1;
    transition: .2s ease-out;
    overflow: hidden;

    .slider-body {
      width: 70%;
      height: 100vh;
      overflow: auto;

      flex: 1;
      padding: 50px;

      @media (max-width: 768px) {
        padding: 50px;
      }
    }
  }

  .active {
    transform: translateX(0);
  }

  .hidden {
    transform: translateX(-100%);
  }

}