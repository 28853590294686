@font-face {
  font-family: 'selectum-pro';
  src:  url('fonts/selectum-pro.eot?zb3hk1');
  src:  url('fonts/selectum-pro.eot?zb3hk1#iefix') format('embedded-opentype'),
    url('fonts/selectum-pro.ttf?zb3hk1') format('truetype'),
    url('fonts/selectum-pro.woff?zb3hk1') format('woff'),
    url('fonts/selectum-pro.svg?zb3hk1#selectum-pro') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'selectum-pro' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-close:before {
  content: "\e91a";
}
.icon-edit:before {
  content: "\e91b";
}
.icon-change-man:before {
  content: "\e919";
}
.icon-tinker:before {
  content: "\e918";
}
.icon-settings:before {
  content: "\e907";
}
.icon-check-bold:before {
  content: "\e908";
}
.icon-molecula:before {
  content: "\e909";
}
.icon-eye:before {
  content: "\e90a";
}
.icon-tools:before {
  content: "\e90b";
}
.icon-roll:before {
  content: "\e90c";
}
.icon-consultant:before {
  content: "\e90d";
}
.icon-coach:before {
  content: "\e90e";
}
.icon-bricks-man:before {
  content: "\e90f";
}
.icon-bricks:before {
  content: "\e910";
}
.icon-devops:before {
  content: "\e911";
}
.icon-database:before {
  content: "\e912";
}
.icon-diagram:before {
  content: "\e913";
}
.icon-security:before {
  content: "\e914";
}
.icon-web-svgrepo-com:before {
  content: "\e915";
}
.icon-code:before {
  content: "\e916";
}
.icon-network:before {
  content: "\e917";
}
.icon-full-screen-on:before {
  content: "\e900";
}
.icon-full-screen-off:before {
  content: "\e901";
}
.icon-language:before {
  content: "\e902";
}
.icon-menu:before {
  content: "\e903";
}
.icon-arrow-right-circle:before {
  content: "\e904";
}
.icon-arrow-slider:before {
  content: "\e905";
}
.icon-swipe-right:before {
  content: "\e906";
}
.icon-arrow-right2:before {
  content: "\ea3c";
}
