@import "../../../assets/varibles";
@import url('https://fonts.googleapis.com/css2?family=Archivo+Black&family=Montserrat:ital,wght@0,100..900;1,100..900&family=Raleway:ital,wght@0,100..900;1,100..900&display=swap');

::-webkit-scrollbar {
  width: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: transparent;
  border: 0;

}

::-webkit-scrollbar-track {
  background-color: transparent;
}

html, .text-scroll, .scrollable {
  scrollbar-width: thin;
  scrollbar-color: $hover-color transparent;
}

@media (max-width: 768px) {
  .mobile-edge {
    padding-bottom: 50vh!important;
  }
}



.scrollable-q {
  scrollbar-color: #ccc transparent;
}

body {
  padding: 0;
  margin: 0;
  background: #222;
  overflow: hidden;

  h1, h2, h3, h4, h5, h6, ul, li {
    padding: 0;
    margin: 0;
  }

  main {
    height: 100vh;
  }

  main, input, textarea, .input-field {
    font-family: "Montserrat", sans-serif;
    font-optical-sizing: auto;
    font-style: normal;
    font-weight: 500;
    color: #333333;
  }

  input, textarea {
    font-size: 14px;
  }

  * {
    user-select: none;
  }


}